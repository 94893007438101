// Function to create and return a new page with a header, footer
const createPage = (components, type) => {
  const pageDiv = document.createElement('div');
  pageDiv.className = 'page row wrap ' + (type || '');

  // Create and append the header section
  const headerDiv = document.createElement('div');
  headerDiv.className = 'print-header';

  const headerElement = document.querySelector('#exportTitleBarAP > header');

  if (headerElement) {
    headerElement.className = '';
    const headerElementCopy = headerElement.cloneNode(true);
    headerDiv.append(headerElementCopy)
  }

  pageDiv.appendChild(headerDiv);

  // Swap second and third components if the second component is lg12 (2 page score)
  if (components.length === 3 && components[1].classList.contains('lg12')) {
    [components[1], components[2]] = [components[2], components[1]];
  }
  // Append each component to the newly created page
  components.forEach(element => {
    pageDiv.appendChild(element);
  });

  // Create and append the footer section
  const footerDiv = document.createElement('div');
  footerDiv.className = 'print-footer';
  
  const footerElement = document.querySelector('#adPortalFooter > .c360-footer');

  if (footerElement) {
    const footerElementCopy = footerElement.cloneNode(true);
    footerDiv.append(footerElementCopy)
  }

  pageDiv.appendChild(footerDiv);

  return pageDiv;
};

// Function to process tactic layouts
const processGridBasedLayouts = () => {
  const isBroadcast = window.location.href.includes('tab=broadcast');

  const rootElem = document.querySelector('main .grid-list-xl .position');
  if (!rootElem) return 0;
  let componentsBuffer = [];
  let pageScore = 0;
  let pagesCount = 0;
  const alreadyProcessed = [];
  const componentElements = Array.from(rootElem.children);
  // Move InnovidXP components to the new page flag
  let innovidXPStarted = false;
  componentElements.forEach((element, index) => {
    // Skip components used in Fill the gape activity
    if (alreadyProcessed.includes(element)) return;
    const componentScore = element.classList.contains('lg12') ? 2 : 1;
    const isCurrentInnovidXP = element?.id?.includes('InnovidXP');
    // Creates new page for the first InnovidXP component
    if (!innovidXPStarted && isCurrentInnovidXP && isBroadcast && componentsBuffer.length !== 0) {
      // Create new page
      pagesCount += 1;
      rootElem.appendChild(createPage(componentsBuffer));
      componentsBuffer = [];
      pageScore = 0;
      innovidXPStarted = true;
    }
    const isLast = index === componentElements.length - 1;
    // Check if element is visible
    if (element.offsetHeight > 0) {
      // Create a new page when the pageScore exceeds 4 and the component doesn't fit on the current page, but the page is not yet full
      if (pageScore + componentScore > 4) {
        // search for a better fit element
        let foundBetterFit = false;
        // Fill the gap with the next element
        for (let j = index + 1; j < componentElements.length; j++) {
          const nextElement = componentElements[j];
          const isNextInnovidXP = nextElement?.id?.includes('InnovidXP');
          // Skip InnovidXP components on Broadcast tactic from using as a filler
          if (isBroadcast && isNextInnovidXP) {
            continue;
          }
          const nextComponentScore = nextElement.classList.contains('lg12') ? 2 : 1;

          if (pageScore + nextComponentScore <= 4 && nextElement.offsetHeight > 0) {
            componentsBuffer.push(nextElement);
            nextElement.parentNode.removeChild(nextElement);
            alreadyProcessed.push(nextElement);
            foundBetterFit = true;
            // Create new page
            pagesCount += 1;
            rootElem.appendChild(createPage(componentsBuffer));
            componentsBuffer = [];
            pageScore = 0;
            break;
          }
        }

        if (!foundBetterFit) {
          // Create new page
          pagesCount += 1;
          rootElem.appendChild(createPage(componentsBuffer));
          componentsBuffer = [];
          pageScore = 0;
        }
      }

      componentsBuffer.push(element);
      pageScore += componentScore;
    }
    // Remove the element from the original DOM to reduce the number of elements on the page
    element.parentNode.removeChild(element);
    // Create a new page when the current page is full or if this is the last element on a partially filled page
    if (pageScore === 4 || (isLast && componentsBuffer.length > 0)) {
      // Create new page
      pagesCount += 1;
      rootElem.appendChild(createPage(componentsBuffer));
      componentsBuffer = [];
      pageScore = 0;
    }
  });
  // Adjust the height of the root element to accommodate all pages
  // The height is calculated based on the number of pages and a 150% zoom factor from hove.vue css section
  rootElem.style.setProperty('height', ( pagesCount * 8.5 * 1.5 ) + 'in', 'important');
  rootElem.style.overflow = 'hidden';
  return pagesCount;
};

// Process summary and tactic summary layouts. Dynamic rows per page based on the height of the components.
// TODO: Potentially, the place to process orderList and homePage layouts:
//   - Add class to root container in the view
//   - Add root element here, update rootElem query selector.
//   - Add styles inside .print {} in home vue file.
const processRowsBasedLayouts = initialPages => {
  const componentsList = document.querySelector('main .grid-list-xl .position .campaign-list-container, .layout.summary');
  // Root for tactic summary layout, otherwise using summary layout root.
  const rootElem = document.querySelector('main .grid-list-xl .position, .layout.summary');
  if (!componentsList || !rootElem) return;
  let componentsBuffer = [];
  let pagesCount = initialPages;
  const summaryComponentElements = Array.from(componentsList.children);
  const availableHeight = 1100;
  let currentHeight = 0;
  componentsBuffer = [];

  summaryComponentElements.forEach((element, index) => {
    if (!element) return;
    const componentHeight = element.offsetHeight;
    if (currentHeight + componentHeight <= availableHeight) {
      componentsBuffer.push(element);
      currentHeight += componentHeight;
    } else if (currentHeight + componentHeight > availableHeight) {
      pagesCount += 1;
      rootElem.appendChild(createPage(componentsBuffer, 'summary'));
      componentsBuffer = [];
      componentsBuffer.push(element);
      currentHeight = componentHeight;
    }
    if (index === summaryComponentElements.length - 1) {
      pagesCount += 1;
      rootElem.appendChild(createPage(componentsBuffer, 'summary'));
      componentsBuffer = [];
    }
  });
  // Adjust the height of the root element to accommodate all pages
  // The height is calculated based on the number of pages and a 150% zoom factor from hove.vue css section
  rootElem.style.setProperty('height', ( pagesCount * 8.5 * 1.5 ) + 'in', 'important');
  rootElem.style.overflow = 'hidden';
  return;
};

// Function to prepare the pages by organizing components into paginated sections
export const preparePages = () => {
  // Root for tactic or summary layouts. Grid 4 elements on a page.
  const createdPages = processGridBasedLayouts();
  // Process summary and tactic summary layouts. Dynamic rows per page based on the height of the components.
  processRowsBasedLayouts(createdPages);
};
